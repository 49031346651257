<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
          >
            导出
          </el-button>
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #status="{ scope }">
        <span :class="scope.row.status == 2 ? 'text-success' : 'text-danger'">
          {{ scope.row.status == 2 ? '已处理' : '未处理' }}
        </span>
      </template>
      <template #action="{ scope }">
        <el-button
          size="mini"
          type="primary"
          v-if="scope.row.status === 2"
          @click="handleAction(scope.row)"
        >
          查看
        </el-button>
        <el-button
          size="mini"
          type="primary"
          v-else
          @click="handleAction(scope.row)"
        >
          处理
        </el-button>
      </template>
    </default-table>

    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      width="630px"
      custom-class="dialog"
      :title="dialogTitle"
    >
      <ele-form
        v-model="formData"
        :form-desc="formDesc"
        labelPosition="right"
        :span="24"
        :isShowSubmitBtn="false"
        :isShowBackBtn="false"
        :disabled="formDisabled"
      >
        <div style="text-align: center" v-show="check" slot="form-footer">
          <el-button type="primary" size="mini" @click="handleSubmit">
            提交
          </el-button>
          <el-button type="info" plain size="mini" @click="visible = false">
            取消
          </el-button>
        </div>
      </ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import {
  QueryFactoryList,
  ExportFactory,
  FactoryListError
} from '@/api/alarms';

import mixins from '@/mixins';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable
  },
  data() {
    return {
      tableLoading: true,
      gas: '',
      factory: '',
      searchParams: {
        site_id: ''
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          fixed: 'left',
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          fixed: 'left',
          width: 190
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          align: 'center'
          // minWidth: 100
        },
        {
          title: '端口编号',
          align: 'center',
          key: 'port'
        },
        {
          title: '监控微处理器编号',
          key: 'cpu_code',
          align: 'center',
          minWidth: 120
          // width: 140
        },
        {
          title: '监控微处理器使用厂家',
          key: 'cpu_code_factory',
          align: 'center',
          minWidth: 150
          // width: 140
        },
        {
          title: '编码器号',
          key: 'code_num'
          // width: 80
        },
        {
          title: '编码器使用厂家',
          key: 'code_num_factory',
          minWidth: 118
          // width: 80
        },
        {
          title: '油机铭牌厂家',
          key: 'factory',
          minWidth: 118
          // width: 80
        },
        {
          title: '状态',
          key: 'status',
          align: 'center',
          width: 90,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          key: 'action',
          width: 90,
          align: 'center',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      },

      visible: false,
      check: false,
      formData: {},
      formDisabled: false,
      dialogTitle: '',
      formDesc: {
        // site_name: {
        //   type: 'input',
        //   label: '加油站名称',
        //   disabled: true
        // },
        // tanker_name: {
        //   type: 'input',
        //   label: '加油机名称',
        //   disabled: true
        // },
        remark: {
          type: 'textarea',
          label: '备注信息'
        }
      }
    };
  },
  computed: {
    title() {
      const currentName = this.$router.history.current.name;
      return this.$locales[currentName];
    }
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      QueryFactoryList(params).then(res => {
        // console.log(res);
        this.data = res.data;
        if (res.code === 0) {
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        } else {
          this.pagination = {
            ...this.pagination,
            total: 0
          };
        }
        this.tableLoading = false;
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      ExportFactory(params)
        .then(res => {
          this.$ExportFile(res, this.title);
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleAction(row) {
      this.visible = true;
      this.formData = row;
      if (row.status == 2) {
        this.dialogTitle = '处理结果';
        this.formDisabled = true;
        this.check = false;
      } else {
        this.dialogTitle = '提交处理结果';
        this.formDisabled = false;
        this.check = true;
      }
    },
    handleSubmit() {
      console.log(this.formData);
      FactoryListError({
        id: this.formData.id,
        remark: this.formData.remark
      }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg);
          this.visible = false;
          this.handleQuery({
            current: this.pagination.current,
            pageSize: this.pagination.pageSize
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
